<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.history") }}
      <v-spacer></v-spacer>

      <v-btn color="error" small outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-row dense class="font-weigh-medium">
        <v-col>
          {{ $t("labels.reserved_goods_status_1") }}:
          <span class="primary--text font-weight-medium">
            {{ item.quantity }}
          </span>
        </v-col>
        <v-col>
          {{ $t("labels.reserved_goods_status_0") }}:
          <span class="error--text font-weight-medium">
            {{ item.cancel_quantity }}
          </span>
        </v-col>
        <v-col>
          {{ $t("labels.remaining") }}:
          <span class="success--text font-weight-medium">
            {{ item.remaining_quantity }}
          </span>
        </v-col>
      </v-row>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.time") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.quantity") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="history in items"
              :key="`h_${history.id}`"
            >
              <td>{{ formatDateTime(history.created_at) }}</td>
              <td>{{ history.quantity }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ReservedCancelHistory",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
  }),
  computed: {},
  watch: {},
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        const { data } = await httpClient.post("/get-reserved-cancel-history", {
          id: this.item.id,
        });
        this.items = [...data];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    closeDialog() {
      this.$emit("cancel", true);
    },
  },
};
</script>

<style scoped></style>
